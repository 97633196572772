.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
    }
}